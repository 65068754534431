import { Taxes } from 'generated/graphql';
import { teamUtils } from 'lib/teams';

const taxes = {
  stored: [],
  save(param: any) {
    taxes.stored = param;
  },
  getAll() {
    return taxes.stored;
  },
  getByCountry(currencyCode: string) {
    return taxes.stored.find((item: any) => item.country_code === currencyCode);
  },
  getTaxes(): Taxes | undefined {
    const currency = teamUtils.getCurrency();
    let taxesForCountry: Taxes | undefined = taxes.getByCountry(currency.code);

    // If the taxes for the recognized country cannot be found, it should default to USD
    if (!taxesForCountry) {
      taxesForCountry = taxes.getByCountry(teamUtils.getDefaultCurrency().code);
    }

    return taxesForCountry;
  },
};

export { taxes };
