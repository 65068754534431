import { useState } from 'react';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import momentTimezone from 'moment-timezone';
import { withAppContext } from 'components/HOCS';
import { Modal, Button, LoadingOverlay, BoxMessage } from 'components/common';
import { bookingUtils } from 'lib/booking';
import { teamUtils } from 'lib/teams';
import { utils, update } from 'lib/utils';
import { taxes } from 'lib/taxes';
import { apolloFetch } from 'lib/http';
import { Coins } from 'lucide-react';

// Queries
import fetchBookingById from 'data/queries/booking/fetchBookingById';

// Mutations
import { Booking, useDeleteOnlineTaskLinkMutation } from 'generated/graphql';

// Styles
import './styles/modalCompleteOrder.scss';
import { FetchCreditPacksQuery } from 'data/queries/credits/fetchCreditPacks';
import { useMutation, useQuery } from 'urql';
import { CompleteOrderBooking } from '../data/CompleteOrderBooking.mutation';

type Props = {
  booking: Booking;
  open: boolean;
  onClose: () => void;
  updateBooking: (bookingState: Booking, objectToUpdate: any) => void;
  createSession: (bookingState: Booking, defaultSession: any, redirectTo?: string | null) => void;
  deleteSession: (bookingState: Booking, sessionId: string) => void;
  onFinishUpdateBooking: (updateTyoe: string) => void;
  openNotifyParticipants: boolean;
  addingMoreParticipants?: number;
  context: any;
  history: any;
};

function ModalCompleteOrder(props: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const addingMoreParticipants = props.addingMoreParticipants || 0;
  const participantsToChangeCredits = _.get(props, 'booking.participantsToChangeCredits') || 0;
  const creditsPerParticipantDifference = _.get(props, 'booking.creditsPerParticipantDifference') || 0;
  const newSessionDuration = _.get(props, 'booking.newSessionDuration') || 0;
  const toolName = _.get(props, 'booking.toolName') || null;
  const oldToolName = _.get(props, 'booking.oldToolName') || null;
  const participantsReopenBooking = _.get(props, 'booking.participantsReopenBooking') || null;

  const [, completeOrderBooking] = useMutation(CompleteOrderBooking);
  const [deleteOnlineTaskLink] = useDeleteOnlineTaskLinkMutation();

  const [{ data: creditsPacks }] = useQuery({ query: FetchCreditPacksQuery });

  const onClickBuyBulk = () => {
    props.onClose();

    props.context.onOpenBuyCreditsModal({
      team_id: _.get(props, 'booking._team_id'),
      creditsToBuy: 10000,
      onSuccess: () => onCompleteOrder(),
    });
  };

  const onCompleteOrder = async () => {
    setLoading(true);
    const currency = teamUtils.getCurrency();

    // Remove unused configs
    if (props.updateBooking) {
      const { data }: any = await apolloFetch.fetch(fetchBookingById, {
        id: _.get(props, 'booking._id'),
        currency_code: currency.code,
      });

      if (data && _.get(data, 'bookingByID')) {
        const bookingData = _.get(data, 'bookingByID');

        let bookingObj = {};
        if (!bookingUtils.isBookingForProfessionals(bookingData)) {
          let filtersOrder = _.clone(_.get(bookingData, 'config.criteria.filters_order')) || [];
          const employmentTypeIndex = _.findIndex(filtersOrder, (item: any) => item === 'meta_work_employment_type');
          if (employmentTypeIndex >= 0) {
            filtersOrder = update(filtersOrder, {
              $splice: [[employmentTypeIndex, 1]],
            });
          }
          const workIndustryIndex = _.findIndex(filtersOrder, (item: any) => item === 'meta_work_industry');
          if (workIndustryIndex >= 0) {
            filtersOrder = update(filtersOrder, {
              $splice: [[workIndustryIndex, 1]],
            });
          }

          bookingObj = {
            config: {
              criteria: {
                meta_work_employment_type: null,
                meta_work_industry: null,
                filters_order: filtersOrder,
              },
            },
          };
          await props.updateBooking(props.booking, bookingObj);
        }

        if (
          bookingUtils.isOnlineTask(bookingData) &&
          bookingUtils.isBookingAutomated(bookingData) &&
          _.size(_.get(bookingData, 'config.online_task.links')) > 1
        ) {
          const linksToRemove = _.slice(_.get(bookingData, 'config.online_task.links'), 1, 99);
          await Promise.all(
            _.map(linksToRemove, (item: any) => {
              deleteOnlineTaskLink({
                variables: {
                  booking_id: _.get(bookingData, '_id'),
                  link_id: item._id,
                },
              });
            }),
          );
        }

        if (bookingUtils.isOnlineTask(bookingData) || bookingUtils.isLongitudinal(bookingData)) {
          if (_.size(_.get(bookingData, 'session')) === 0) {
            let predefinedCompletedBy;
            if (bookingUtils.isOnlineTask(bookingData)) {
              predefinedCompletedBy = momentTimezone(momentTimezone().add(5, 'days')).endOf('day');
            } else if (bookingUtils.isLongitudinal(bookingData)) {
              const daysPeriod = bookingUtils.getLongitudinalStudyPeriod(bookingData) || 5;
              predefinedCompletedBy = momentTimezone(momentTimezone().add(daysPeriod, 'days')).endOf('day');
            }
            const defaultSession = {
              start: predefinedCompletedBy?.valueOf(),
              end: predefinedCompletedBy?.valueOf(),
            };
            await props.createSession(bookingData, defaultSession);
          } else {
            const sessionsToRemove = _.get(bookingData, 'session').slice(1, 99);
            await Promise.all(
              _.map(sessionsToRemove, (session: any) => {
                props.deleteSession(bookingData, session._id);
              }),
            );
          }
        }
      }
    }

    try {
      const { error: completeOrderBookingError } = await completeOrderBooking({
        booking_id: _.get(props, 'booking._id'),
        credits_per_participant_difference: creditsPerParticipantDifference,
        new_session_duration: newSessionDuration,
        participants_added: addingMoreParticipants,
        participants_reopen_booking: participantsReopenBooking,
        participants_session_changed: participantsToChangeCredits,
      });

      if (completeOrderBookingError) {
        // eslint-disable-next-line
        throw completeOrderBookingError.graphQLErrors[0].message;
      }

      if (addingMoreParticipants > 0 || participantsToChangeCredits > 0 || participantsReopenBooking > 0) {
        props.onClose();

        let updateType = '';
        if (addingMoreParticipants > 0) {
          updateType = 'newParticipantsAdded';
        } else if (participantsReopenBooking > 0) {
          updateType = 'reopenBooking';
        } else if (newSessionDuration > 0) {
          updateType = 'sessionDurationChanged';
        } else if (toolName) {
          updateType = 'remoteToolChanged';
        }

        setLoading(false);

        props.context.onCloseBuyCreditsModal({ shouldGoBack: false });
        if (props.onFinishUpdateBooking) props.onFinishUpdateBooking(updateType);

        props.history.replace({
          pathname: `/bookings/${_.get(props, 'booking._id')}/settings`,
          state: {
            booking: props.booking,
            openNotifyParticipants: props.openNotifyParticipants,
            oldSessionDuration: _.get(props, 'booking.config.session.duration'),
            newSessionDuration,
            toolName,
            oldToolName,
          },
        });
      } else {
        props.context.onCloseBuyCreditsModal({ shouldGoBack: false });
        setLoading(false);
        props.history.replace({
          pathname: `/bookings/${_.get(props, 'booking._id')}/participants/all`,
          state: { booking: props.booking },
        });
      }
    } catch (e: any) {
      setLoading(false);
      setError(e);
    }
  };

  const renderErrorContainer = () => {
    if (error) {
      return (
        <BoxMessage type="error mbottom20">
          <span className="errorMessage">{error}</span>
        </BoxMessage>
      );
    }
  };

  const renderCostContainer = (creditsToBuy: any) => {
    const taxesForCountry: any = taxes.getTaxes();
    const currency = teamUtils.getCurrency();
    const pricePerParticipant = bookingUtils.getPricePerParticipant(creditsPacks?.creditPacks as [], creditsToBuy);

    let bookingPrice = _.get(props.booking, 'total_amount.bookingPrice', 0) || 0;
    let bookingPriceTaxes = _.get(props.booking, 'total_amount.bookingPriceTaxes', 0) || 0;
    let bookingPriceAfterTaxes = _.get(props.booking, 'total_amount.bookingPriceAfterTaxes', 0) || 0;

    if ((props.addingMoreParticipants || 0) > 0 || participantsToChangeCredits > 0) {
      bookingPrice = creditsToBuy * pricePerParticipant;
      bookingPriceTaxes = taxesForCountry?.tax_rate > 0 ? (bookingPrice * (taxesForCountry?.tax_rate || 0)) / 100 : 0;
      bookingPriceAfterTaxes = bookingPrice + bookingPriceTaxes;
    }

    return (
      <div className="costToBuyCreditsContainer">
        <p>
          You need another <strong>{utils.numberWithCommas(creditsToBuy)} credits</strong>
        </p>
        <div className="totalAmountContainer">
          <div className="breakdownSubtitleContainer">
            <div className="child">
              <span>
                {utils.numberWithCommas(creditsToBuy)} credits ({currency.symbol}
                {pricePerParticipant} {currency.code} each)
              </span>
              <span>
                {currency.symbol}
                {utils.formatMoney(bookingPrice, 2)} {currency.code}
              </span>
            </div>
            {taxesForCountry?.tax_rate && taxesForCountry.tax_rate > 0 && (
              <div className="child">
                <span>{taxesForCountry.tax_label}</span>
                <span>
                  {currency.symbol}
                  {utils.formatMoney(bookingPriceTaxes, 2)} {currency.code}
                </span>
              </div>
            )}
          </div>
          <div className="totalContainer">
            <div className="child">
              <span>Total</span>
              <span className="total">
                {currency.symbol}
                {utils.formatMoney(bookingPriceAfterTaxes, 2)} {currency.code}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderButtonLabel = (creditsToBuy: any) => {
    if (creditsToBuy > 0 && teamUtils.getCredits(_.get(props, 'booking.team')) === 0) {
      return `Buy ${creditsToBuy} credits`;
    }
    if (creditsToBuy > 0) {
      return `Top-up with ${creditsToBuy} credits`;
    }
    return 'Complete Order';
  };

  const creditsRemaining = teamUtils.getCredits(_.get(props, 'booking.team'));
  let creditsPerParticipant = bookingUtils.getCreditsPerParticipant(props.booking);
  let creditsFaqLabel = _.get(bookingUtils.getBookingTypeSettings(props.booking), 'completeOrderLabel');
  let creditsToBeUsed = Math.ceil((props.booking?.total_participants || 0) * creditsPerParticipant);
  let participantLabel = props.booking.total_participants === 1 ? 'participant' : 'participants';
  if (addingMoreParticipants > 0 && _.has(props.booking, 'config.credits_per_participant')) {
    creditsPerParticipant = _.get(props.booking, 'config.credits_per_participant');
    creditsFaqLabel = `${creditsPerParticipant} credits ${bookingUtils.isOnlineTask(props.booking) ? 'per response' : 'each'}`;
    creditsToBeUsed = Math.ceil((props.booking?.total_participants || 0) * creditsPerParticipant);
  }
  if (participantsToChangeCredits > 0) {
    creditsPerParticipant = creditsPerParticipantDifference;
    creditsFaqLabel = `${creditsPerParticipant} extra credits ${bookingUtils.isOnlineTask(props.booking) ? 'per response' : 'each'}`;
    creditsToBeUsed = Math.ceil(participantsToChangeCredits * creditsPerParticipant);
    participantLabel = participantsToChangeCredits === 1 ? 'participant' : 'participants';
  }
  const creditsToBuy = creditsToBeUsed - creditsRemaining;
  let parsedDuration = '';
  if (newSessionDuration) {
    parsedDuration = utils.getLabelFromArray(
      bookingUtils.sessionDurations(_.get(props, 'booking.type'), _.get(props, 'booking.config.online_task.type')),
      newSessionDuration,
    );
  }
  return (
    <Modal open={props.open} onClose={props.onClose} className="modalCompleteOrder">
      {loading && <LoadingOverlay style={{ opacity: 0.7 }} />}
      <div className="contentModal">
        {renderErrorContainer()}
        <div className="bookingContainer">
          {addingMoreParticipants > 0 && (
            <p id="__extraParticipantsTitle" className="extraParticipantsTitle">
              Add {addingMoreParticipants} extra {participantLabel}
            </p>
          )}
          {participantsToChangeCredits > 0 && newSessionDuration > 0 && (
            <p className="extraParticipantsTitle">Changing session to {parsedDuration}</p>
          )}
          {participantsToChangeCredits > 0 && toolName && (
            <p className="extraParticipantsTitle">Changing tool to Askable Sessions</p>
          )}
          <div className="topHeaderContainer">
            <div className="bookingDetailsContainer">
              {participantsToChangeCredits > 0 && (
                <h3>
                  {participantsToChangeCredits} x {bookingUtils.getBookingParticipantCategoryShortLabel(props.booking)}{' '}
                  {participantLabel}
                </h3>
              )}
              {/* @ts-expect-error ts-migrate(2365) FIXME: Operator '>' cannot be applied to types 'boolean' ... Remove this comment to see the full error message */}
              {!participantsToChangeCredits > 0 && (
                <h3>
                  {_.get(props, 'booking.total_participants')} x {addingMoreParticipants > 0 ? 'extra' : ''}{' '}
                  {bookingUtils.getBookingParticipantCategoryShortLabel(props.booking)} {participantLabel}
                </h3>
              )}
              <h3 className="bookingType">{_.get(bookingUtils.getBookingTypeSettings(props.booking), 'label')}</h3>
            </div>
            <p id="__creditsFAQ" className="creditsFAQ">
              {creditsFaqLabel}
            </p>
          </div>
          <div className="creditsContainer">
            <div className="title">
              <h2 id="__extraCredits">{utils.numberWithCommas(creditsToBeUsed)} credits</h2>
              <a
                href="https://help.askable.com/en/articles/4254373-ways-of-buying-credits"
                target="blank"
                className="mleft15"
              >
                (What&apos;s a credit?)
              </a>
            </div>
            <p>Incentives included</p>
          </div>
        </div>
        <div className="prepaidCreditsBalanceContainer">
          <p className="font--bold">Your prepaid credits balance</p>
          <div className="numberOfCredits">
            <Coins color="#b4b4b4" className="h-4 w-4" />
            <p className={`${creditsRemaining === 0 ? 'noCredits' : ''}`}>
              <strong>
                {utils.numberWithCommas(creditsRemaining)} credit{creditsRemaining > 1 ? 's' : ''}
              </strong>{' '}
              remaining
            </p>
          </div>
        </div>
        {creditsToBuy > 0 && renderCostContainer(creditsToBuy)}
        <div className="buttonsContainer">
          <Button
            label={renderButtonLabel(creditsToBuy)}
            size="default"
            className="btnComplete"
            onClick={() => {
              if (creditsToBuy > 0) {
                props.onClose();
                sessionStorage.setItem('creditsToBuy', creditsToBuy.toString());
                props.context.onOpenBuyCreditsModal({
                  team_id: _.get(props, 'booking._team_id'),
                  creditsToBuy,
                  _booking_id: _.get(props, 'booking._id'),
                  onSuccess: () => onCompleteOrder(),
                });
              } else {
                onCompleteOrder();
              }
            }}
          />
          <p>
            or buy a bulk credit pack and
            <a
              className="mleft5"
              onClick={() => {
                onClickBuyBulk();
              }}
            >
              save up to 30%
            </a>
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default deprecatedWithRouter(withAppContext(ModalCompleteOrder));
