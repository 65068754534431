import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useMutation } from 'urql';
import { useIsKinde } from 'containers/Auth/AuthWrapper';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { useFeatureFlags } from 'feature-flags';

import { LanguageSwitcher, LoadingOverlay, PhoneInput } from 'components/common';
import LocationAutocomplete from 'components/common/LocationAutocomplete/view';
import { SettingsContainer } from '../SettingsContainer';
import { SectionHeader } from '../SectionHeader';
import { UploadProfileImage } from './components/UploadProfileImage';
import { Alert, AlertDescription } from '@askable/ui/components/ui/alert';
import { Button } from '@askable/ui/components/ui/button';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { toast } from '@askable/ui/components/ui/sonner';

import { localStorage } from 'lib/storage';
import { location } from 'lib/location';
import { taxes } from 'lib/taxes';
import { utils } from 'lib/utils';
import { validations } from 'lib/validations';

import { useProfilePicture } from 'src/hooks/useProfilePicture';
import fetchUserById from 'data/queries/user/fetchUserById';
import updateMeMutation from 'data/mutations/user/updateMe';
import { Location, UpdateMeMutation, UpdateMeMutationVariables } from 'generated/graphql';

export const SettingsAccount = () => {
  const isKinde = useIsKinde();
  const { t } = useTranslation();
  const { details } = useConnectedClient();
  const { loading: loadingUser, data: userData } = useQuery(fetchUserById);
  const [, updateMe] = useMutation<UpdateMeMutation, UpdateMeMutationVariables>(updateMeMutation);
  const { refetch: refetchUser } = useQuery(fetchUserById);
  const { addProfilePicture, removeProfilePicture, loading: profileLoading } = useProfilePicture();
  const { TRANSLATIONS } = useFeatureFlags(['TRANSLATIONS'], { application: 'clients' });
  const taxesForCountry = taxes.getTaxes();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [saving, setSaving] = useState(true);
  const [locationName, setLocationName] = useState('');
  const [mobile, setMobile] = useState('');
  const [userLocation, setUserLocation] = useState({} as Location);
  const [userTaxRegistrationNumber, setUserTaxRegistrationNumber] = useState('');

  useEffect(() => {
    setEmail(userData?.userByID.email);
    setFirstname(userData?.userByID.meta?.identity?.firstname || '');
    setLastname(userData?.userByID.meta?.identity?.lastname || '');
    setMobile(userData?.userByID.contact?.phone?.mobile || '');
    setUserTaxRegistrationNumber(userData?.userByID.settings?.billing?.registration || '');

    setSaving(false);
  }, [userData]);

  const validateFields = () => {
    validations.startValidations();
    let valid = true;

    if (
      !validations.validateEmail(email) ||
      !firstname ||
      !lastname ||
      !validations.validatePhoneNumber(mobile, userData?.userByID.contact?.phone?.country_code)
    ) {
      valid = false;
    }

    return valid;
  };

  const onSave = async (event: any) => {
    event.preventDefault();

    if (validateFields()) {
      setSaving(true);
      setError('');

      const userState = {
        meta: {
          identity: {
            firstname,
            lastname,
          },
        },
        contact: {
          phone: {
            mobile,
            country_code: userData?.userByID.contact?.phone?.country_code || localStorage.get('countryByIp') || '',
          },
        },
        email,
        location: {
          name: locationName,
          postal_code: userLocation.postal_code,
          city: userLocation.city,
          state: userLocation.state,
          country: userLocation.country,
          latitude: userLocation.latitude,
          longitude: userLocation.longitude,
          region: userLocation.region,
        },
        settings: {
          billing: {
            registration: userTaxRegistrationNumber,
          },
        },
      };

      try {
        await updateMe({ user: { ...userState } });
        localStorage.save(
          'client_details',
          JSON.stringify({
            ...utils.parseJSON(localStorage.get('client_details')),
            email,
            name: {
              firstname,
              lastname,
            },
            phone: mobile,
          }),
        );

        // Update local storage user location
        if (locationName) {
          location.saveLocationFromUserData();
        }

        await refetchUser();

        toast.success(
          t('confirmations.updated', {
            title: t('sections.settings.profile.title'),
          }),
        );
      } catch (err: any) {
        setError(err?.graphQLErrors[0].message);
      } finally {
        setSaving(false);
      }
    } else {
      toast.error('Invalid fields');
    }
  };

  const handleRemoveProfilePicture = async () => {
    setError('');
    try {
      const { error: errorRemoveProfilePicture } = await removeProfilePicture();
      if (errorRemoveProfilePicture) {
        throw new Error(errorRemoveProfilePicture.message);
      }
      // this is needed here because the mutation to delete the profile picture (urql) does
      // not invalidate the user cache which uses apollo so we need to manually refetch the user.
      await refetchUser();
      toast.success(t('confirmations.updated', { title: t('sections.settings.profile.profilePicture.title') }));
    } catch (err) {
      setError((err as Error).message);
    }
  };

  const handleAddProfilePicture = async (url: string) => {
    setError('');
    try {
      const { errors } = await addProfilePicture(url);
      if (errors?.length) {
        throw new Error(errors[0].message);
      }
      toast.success(t('confirmations.updated', { title: t('sections.settings.profile.profilePicture.title') }));
    } catch (err) {
      setError((err as Error).message);
    }
  };

  const isResearcher = details?.type?.researcher;
  const disabledForm = !!(
    (firstname && firstname.trim() === '') ||
    (lastname && lastname.trim() === '') ||
    (email && email.trim() === '')
  );

  return (
    <SettingsContainer title={t('sections.settings.profile.title')}>
      {loadingUser || profileLoading ? <LoadingOverlay style={{ opacity: 0.8 }} /> : null}

      {error ? (
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      ) : null}

      {isResearcher ? (
        <UploadProfileImage
          userPicture={userData?.userByID.picture}
          userId={userData?.userByID._id}
          onErrorMessage={setError}
          onRemove={handleRemoveProfilePicture}
          onLoading={setSaving}
          onUploadComplete={handleAddProfilePicture}
        />
      ) : null}

      <form onSubmit={onSave} className="flex flex-col gap-8">
        <section className="flex flex-col gap-4">
          <SectionHeader
            title={t('sections.settings.profile.profileDetails')}
            description={t('formFields.allFieldsRequired')}
          />

          <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
            <div className="flex flex-col gap-1">
              <Label htmlFor="__user_firstname">{t('formFields.firstName')}</Label>
              <Input id="__user_firstname" value={firstname} onChange={(e) => setFirstname(e.target.value)} required />
            </div>

            <div className="flex flex-col gap-1">
              <Label htmlFor="__user_firstname">{t('formFields.lastName')}</Label>
              <Input id="__user_lastname" value={lastname} onChange={(e) => setLastname(e.target.value)} required />
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <Label htmlFor="__user_email">{t('formFields.email')}</Label>
            <Input
              disabled={isKinde}
              id="__user_email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col gap-1">
            <Label htmlFor="__user_phone">{t('formFields.phone')}</Label>
            {userData ? (
              <PhoneInput
                id="__user_phone"
                countryCode={userData?.userByID.contact?.phone?.country_code || localStorage.get('countryByIp') || ''}
                phoneNumber={mobile}
                onChange={({ phone }) => setMobile(phone)}
                className="defaultPhoneInput"
              />
            ) : null}
          </div>

          {isResearcher ? (
            <div className="flex flex-col gap-1">
              <Label htmlFor="__user_abn">
                {t('sections.settings.profile.paymentDetails')} ({taxesForCountry?.tax_number_label || 'VAT'})
              </Label>
              <Input
                id="__user_abn"
                value={userTaxRegistrationNumber}
                onChange={(e) => setUserTaxRegistrationNumber(e.target.value)}
              />
            </div>
          ) : null}
        </section>

        <section className="flex flex-col gap-4">
          <SectionHeader title={t('sections.settings.profile.locationCity')} />

          <LocationAutocomplete
            placeholder="e.g. Brisbane QLD, Australia"
            initialSearchValue={
              userData?.userByID.location?.name ||
              `${localStorage.get('cityByIp')} ${localStorage.get('stateName')}, ${localStorage.get('countryName')}`
            }
            onNewPlace={(value) => {
              setUserLocation(value);
              setLocationName(value.formatted_address ?? '');
            }}
            type="(cities)"
            styleWidth={390}
            countryCodes={[userData?.userByID.location?.country ?? localStorage.get('countryByIp') ?? 'AU']}
            canChangeCountry
          />
        </section>

        {TRANSLATIONS ? (
          <section className="flex flex-col gap-4">
            <SectionHeader title={t('sections.settings.profile.changeLanguage')} />
            <LanguageSwitcher />
          </section>
        ) : null}

        <Button type="submit" variant="primary" isLoading={saving} disabled={disabledForm}>
          {t('global.save')}
        </Button>
      </form>
    </SettingsContainer>
  );
};
