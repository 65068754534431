import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'plur... Remove this comment to see the full error message
import pluralize from 'pluralize';
import _ from 'lodash';
import update from 'immutability-helper';

// @ts-expect-error ts-migrate(2339) FIXME: Property 'extend' does not exist on type '<T, C ex... Remove this comment to see the full error message
update.extend('$auto', (value, object) => {
  return object ? update(object, value) : update({}, value);
});

const utils = {
  escapeRegexCharacters(str: any) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  },
  parseJSON<T = any>(value: string | null | undefined) {
    if (typeof value !== 'string' || value === null || value === undefined) {
      return undefined;
    }

    try {
      return JSON.parse(value) as T;
    } catch (err) {
      console.error(`parseJSON failed to parse value: ${value}`);
      console.error(err);
      return undefined;
    }
  },
  formatTimestampToDate(timestamp: any, format = 'DD/MM/YYYY hh:mma') {
    const dateInNumber = parseInt(timestamp, 10);
    return moment(dateInNumber).format(format);
  },
  formatTimeFromX(startTimestamp: any, endTimestamp: any) {
    return moment(startTimestamp).from(moment(endTimestamp), true);
  },
  formatTimeToNow(startTimestamp: any) {
    return moment(startTimestamp).toNow(true);
  },
  camelize(str: any) {
    if (str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match: any, index: any) => {
        if (+match === 0) return '';
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
      });
    }
    return str;
  },
  capitalize(str: any) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  plural(str: any, count: any) {
    if (count > 1) {
      return pluralize.plural(str);
    }
    return str;
  },
  removeAllBlankSpaces(str: any) {
    return str.replace(/ /g, '');
  },
  getCurrentYear() {
    return new Date().getFullYear();
  },
  getXdaysFromNow(daysToAdd: any) {
    return moment().add(daysToAdd, 'd');
  },
  convert24HourTo12Hour(time: any) {
    return moment(time, 'HH:mm').format('hh:mma');
  },
  addDaysToTimestamp(timestamp: any, days: any) {
    return moment(timestamp).add(days, 'days').valueOf();
  },
  addWeekdaysToTimestamp(timestamp: any, days: any) {
    let date = moment(timestamp); // use a clone
    let count = days;
    while (count > 0) {
      date = date.add(1, 'days');
      // decrease "days" only if it's a weekday.
      if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
        count -= 1;
      }
    }
    return date.valueOf();
  },
  normaliseTimestamp(timestamp: any) {
    if (typeof timestamp === 'string') {
      return parseInt(timestamp, 10);
    }
    return timestamp;
  },
  getLabelFromArray(array: any, currentValue: any, field = 'label') {
    if (!array) return currentValue;
    const foundObject = array.find((obj: any) => {
      return obj.value === currentValue;
    });
    if (_.has(foundObject, field)) return foundObject[field];
    return currentValue;
  },
  getYearFromTimestamp(timestamp: any) {
    // Convert timestamp to integer if necessary
    if (typeof timestamp === 'string') {
      return moment(parseInt(timestamp, 10)).year();
    }
    return moment(timestamp).year();
  },
  getDifferenceFromNow(timestamp: any, measurement = 'minutes') {
    // Function will return the diference in a measurement time from now to the timestamp passed as an argument
    // If it returns a negative number it means it's in the past
    // By default the measurement used is minutes, but it can be overriden just by passing an argument
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    return moment(utils.normaliseTimestamp(timestamp)).diff(moment(), measurement);
  },
  getHourFromString(value: any) {
    return value.substr(0, value.indexOf(':'));
  },
  getMinutesFromString(value: any) {
    return value.substr(value.indexOf(':') + 1);
  },
  orderArrayByDate(arraySource: any, dateField: any) {
    return _.sortBy(arraySource, (o: any) => {
      return moment(o[dateField]);
    });
  },
  orderArrayByField(arraySource: any, field: any, order = 'asc', second_field?: any) {
    return _.orderBy(
      arraySource,
      (o: any) => {
        let parentValue = o[field];
        if (typeof o[field] === 'string') parentValue = o[field].toLowerCase();

        // It should test if the parent value is an Array. If it is, it should get the first object to order by
        if (Array.isArray(parentValue)) [parentValue] = parentValue;

        // If a second field is passed then it should go a level deeper
        if (parentValue && second_field) return parentValue[second_field];

        return parentValue;
      },
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      [order],
    );
  },
  groupByDate(arraySource: any, dateField: any, timezone: any, format = 'ddd Do MMM') {
    return _.groupBy(arraySource, (item: any) => momentTimezone(item[dateField]).tz(timezone).format(format));
  },
  convertTimestampToDifferentTimezone(timestamp: any, newTimezone: any, currentTimezone: any) {
    if (!newTimezone) return timestamp;

    // 1 - Convert the current timestamp into a valid time string
    //  Uses the browser timezone if no current timezone is passed
    let timeString = moment(timestamp).format('DD/MM/YYYY hh:mma');
    if (currentTimezone) timeString = momentTimezone(timestamp).tz(currentTimezone).format('DD/MM/YYYY hh:mma');

    // 2 - Attach to the end of the time string the new timezone UTC value
    const utcForTimezone = momentTimezone(timestamp).tz(newTimezone).format('Z');
    timeString = `${timeString} ${utcForTimezone}`;
    // 3 - Get the new timestamp for the time string
    return momentTimezone(timeString, 'DD/MM/YYYY hh:mma Z').tz(newTimezone).valueOf();
  },
  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
  },
  parseQueryParams(query: any) {
    if (!query) return null;
    return new URLSearchParams(query);
  },
  isPerfectSequence(arrayOfValues: any) {
    const perfectArray = Array.apply(0, Array(arrayOfValues.length)).map((x, y) => y);
    return _.isEqual(arrayOfValues.sort(), perfectArray.sort());
  },
  formatMoney(input: any, digits = 0) {
    const transformedInput = digits > 0 ? input.toFixed(digits) : input;
    return transformedInput.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
  },
  isObjectId(input: any) {
    const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$');
    return checkForHexRegExp.test(input);
  },
  getCurrentTime(inSeconds = false, inIsoFormat = false) {
    const dateNow = moment();
    if (inIsoFormat) return dateNow.toISOString(true);
    if (inSeconds) return dateNow.unix();
    return moment.now();
  },
  arrayMove(arr: any, previousIndex: any, newIndex: any) {
    const array = arr.slice(0);
    if (newIndex >= array.length) {
      const k = newIndex - array.length;
      while (k - 1 + 1) {
        array.push(undefined);
      }
    }
    array.splice(newIndex, 0, array.splice(previousIndex, 1)[0]);
    return array;
  },
  getRangeNumbers(start: any, end: any) {
    const range = [];
    for (let i = start; i <= end; i += 1) range.push(i);
    return range;
  },
  getCurrentTimezone() {
    // @TODO https://linear.app/askable/issue/ASK-3901/use-intldatetimeformatresolvedoptionstimezone-for-timezone-detection
    return Intl.DateTimeFormat().resolvedOptions().timeZone || momentTimezone.tz.guess();
  },
  getCurrencySymbol(currencyCode: string) {
    return (0)
      .toLocaleString(undefined, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  },
  convertDateTimeToDate(datetime: any, timezone: any) {
    const m = momentTimezone.tz(datetime, timezone);
    return new Date(m.year(), m.month(), m.date(), m.hour(), m.minute(), 0);
  },
  convertDateToDateTime(date: any, timezone: any) {
    const dateM = momentTimezone.tz(date, timezone);
    const m = momentTimezone.tz(
      {
        year: dateM.year(),
        month: dateM.month(),
        date: dateM.date(),
        hour: dateM.hour(),
        minute: dateM.minute(),
      },
      timezone,
    );
    return m;
  },
  async copy(text: any, onSuccess?: any, onError?: any) {
    if (!window.navigator.clipboard) {
      const textArea = document.createElement('textarea');
      document.body.appendChild(textArea);
      textArea.value = text;
      textArea.focus();
      textArea.setSelectionRange(0, text.length);

      try {
        const successful = document.execCommand('copy');
        if (!successful) throw new Error("document.execCommand('copy') returned false");
        if (onSuccess) onSuccess();
      } catch (err) {
        textArea.blur();
        document.body.removeChild(textArea);
        if (onError) onError();
      }
      textArea.blur();
      document.body.removeChild(textArea);
      return;
    }

    let nativeSuccessful = true;
    await window.navigator.clipboard.writeText(text).catch(() => {
      nativeSuccessful = false;
      if (onError) onError();
    });

    if (nativeSuccessful && onSuccess) onSuccess();
  },
  generateNumbersArray(start = 1, end = 50) {
    const newArray = [];
    for (let i = start; i <= end; i += 1) {
      newArray.push(i);
    }
    return newArray;
  },
  convertToMiliseconds(datetime: any) {
    // It should first check if the given data is rendered in miliseconds already.
    //   Yes -> It should just return it
    //   No  -> It should transform to miliseconds
    if (datetime.toString().length === 13) return datetime;
    return datetime * 1000;
  },
  convertToSeconds(datetime: any) {
    // It should first check if the given data is rendered in miliseconds.
    //   Yes -> It should transform to seconds
    //   No  -> It should just return it
    if (datetime && datetime.toString().length === 13) return moment(datetime).unix();
    return datetime;
  },
  convertToIso(datetime: any) {
    if (datetime && datetime.toString().length === 13) return moment(datetime).toISOString(true);
    return null;
  },
  isPast(timestamp: number) {
    const now = new Date().valueOf();
    return timestamp < now;
  },
  urlBase64ToUint8Array(base64String: any) {
    // const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const padding = '';
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; i += 1) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  },
  orderObjectByValues(objects: any, getMostRecent = false) {
    // Function to order an object instead of an array
    if (getMostRecent) {
      const mostRecentObject = _.chain(objects)
        .toPairs()
        .filter((value: any) => typeof value[1] === 'number')
        .orderBy((value: any) => value[1], ['desc'])
        .head()
        .value();
      return _.fromPairs([mostRecentObject]);
    }
    return _.chain(objects)
      .toPairs()
      .filter((value: any) => typeof value[1] === 'number')
      .orderBy((value: any) => value[1], ['desc'])
      .fromPairs()
      .value();
  },
  groupByDates(source: any, dateField: any, dateFormat = 'D MMMM YYYY') {
    return _.chain(source)
      .groupBy((item: any) => moment(item[dateField]).startOf('day').valueOf())
      .map((group: any, day: any) => {
        return {
          day: moment(parseInt(day, 10)).format(dateFormat),
          group,
          timestamp: moment(parseInt(day, 10)),
        };
      })
      .sortBy('timestamp')
      .value();
  },
  insertHTML(htmlToInsert: any) {
    const sel = window?.getSelection?.();

    if (!sel) {
      return;
    }

    let range = sel.getRangeAt(0);
    range = sel.getRangeAt(0);
    range.collapse(true);
    range.insertNode(htmlToInsert);

    // Move the caret immediately after the inserted span
    range.setStartAfter(htmlToInsert);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
  },
  placeCaretAtEnd(el: any) {
    el.focus();
    if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = window.getSelection();
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      sel.removeAllRanges();
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      sel.addRange(range);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'createTextRange' does not exist on type ... Remove this comment to see the full error message
    } else if (typeof document.body.createTextRange !== 'undefined') {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'createTextRange' does not exist on type ... Remove this comment to see the full error message
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  },
  numberWithCommas(x: any) {
    return _.replace(_.toString(x), /\B(?=(\d{3})+(?!\d))/g, ',') || '';
  },
  // getWeekNumber(d) {
  //     // Copy date so don't modify original
  //     const result = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  //     // Set to nearest Thursday: current date + 4 - current day number
  //     // Make Sunday's day number 7
  //     result.setUTCDate((result.getUTCDate() + 4) - (result.getUTCDay() || 7));
  //     // Get first day of year
  //     const yearStart = new Date(Date.UTC(result.getUTCFullYear(), 0, 1));
  //     // Calculate full weeks to nearest Thursday
  //     const weekNo = Math.ceil((((result - yearStart) / 86400000) + 1) / 7);
  //     // Return array of year and week number
  //     return weekNo;
  // },
  getDaysInMonth(month: any, year: any, timezone: any) {
    const date = momentTimezone.tz([year, month, 1, 0, 0, 0], timezone).toDate();
    const days = [];
    while (date.getFullYear() + date.getMonth() / 12 <= year + month / 12) {
      const dayOfWeek = date.getDay();

      // It should add some days at the beggining of the array from last month
      // This is useful for displaying purposes
      if (days.length === 0 && dayOfWeek !== 0) {
        for (let r = dayOfWeek; r > 0; r -= 1) {
          const d = new Date(date);
          const dateToAdd = new Date(d.setDate(d.getDate() - r));
          days.push({
            date: momentTimezone.tz(dateToAdd, timezone).endOf('day').toDate(),
            outOfMonth: true,
            week: moment(dateToAdd).week(),
          });
        }
      }
      days.push({
        date: momentTimezone.tz(date, timezone).endOf('day').toDate(),
        outOfMonth: false,
        isToday: utils.isToday(date, timezone),
        week: moment(date).week(),
      });
      date.setDate(date.getDate() + 1);
    }
    const lastDayOfMonth = days[days.length - 1].date;
    // It should add some days at the end of the array from the current month
    // This is useful for displaying purposes
    if (lastDayOfMonth.getDay() !== 6) {
      const numberOfDaysToAdd = 6 - lastDayOfMonth.getDay();
      for (let r = 1; r <= numberOfDaysToAdd; r += 1) {
        const d = new Date(lastDayOfMonth);
        const dateToAdd = new Date(d.setDate(d.getDate() + r));
        days.push({
          date: momentTimezone.tz(dateToAdd, timezone).endOf('day').toDate(),
          outOfMonth: true,
          week: moment(dateToAdd).week(),
        });
      }
    }
    return days;
  },
  getDaysInMonths(
    startPeriod = moment().startOf('month'),
    timezone = '',
    endPeriod = startPeriod.clone().add(1, 'month').endOf('month'),
  ) {
    const startDate = momentTimezone(startPeriod).tz(timezone);
    const endDate = momentTimezone(endPeriod).tz(timezone);

    const days = [];
    while (startDate.isBefore(endDate)) {
      days.push({
        date: momentTimezone(startDate).tz(timezone).valueOf(),
        isToday: momentTimezone().tz(timezone).isSame(startDate, 'day'),
        week: startDate.week(),
        timestamp2: momentTimezone(startDate).tz(timezone).format('DD/MM/YYYY hh:mma'),
      });
      // Mutates the startDate
      startDate.add(1, 'd');
    }
    return days;
  },
  isToday(someDate: any, timezone: any) {
    if (timezone) {
      const today = momentTimezone().tz(timezone);
      return (
        someDate.getDate() === today.date() &&
        someDate.getMonth() === today.month() &&
        someDate.getFullYear() === today.year()
      );
    }

    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  },
  getMonthWeek(date = moment()) {
    const firstDay = new Date(date.year(), date.month(), 1).getDay();

    return Math.ceil((date.date() + firstDay) / 7);
  },
  getDaysOfWeek() {
    return [
      { day: 'Sunday', abr: 'S' },
      { day: 'Monday', abr: 'M' },
      { day: 'Tuesday', abr: 'T' },
      { day: 'Wednesday', abr: 'W' },
      { day: 'Thursday', abr: 'T' },
      { day: 'Friday', abr: 'F' },
      { day: 'Saturday', abr: 'S' },
    ];
  },
  getHoursOfDay() {
    return [
      '0 AM',
      '1 AM',
      '2 AM',
      '3 AM',
      '4 AM',
      '5 AM',
      '6 AM',
      '7 AM',
      '8 AM',
      '9 AM',
      '10 AM',
      '11 AM',
      '12 PM',
      '1 PM',
      '2 PM',
      '3 PM',
      '4 PM',
      '5 PM',
      '6 PM',
      '7 PM',
      '8 PM',
      '9 PM',
      '10 PM',
      '11 PM',
    ];
  },
  getHoursMinutesDay() {
    // Should return an array of hours and minutes with 15 minutes incremental
    const arrayAMHours = [];
    const arrayPMHours = [];
    for (let i = 0; i < 12; i += 1) {
      for (let j = 0; j < 4; j += 1) {
        const minutes = j === 0 ? '00' : 15 * j;
        arrayAMHours.push({
          value: `${i}:${minutes} am`,
          hour: i,
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | "00"' is not assignable... Remove this comment to see the full error message
          minutes: parseInt(minutes, 10),
        });
      }
    }
    for (let i = 0; i < 12; i += 1) {
      for (let j = 0; j < 4; j += 1) {
        const minutes = j === 0 ? '00' : 15 * j;
        arrayPMHours.push({
          value: `${i === 0 ? '12' : i}:${minutes} pm`,
          hour: i + 12,
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | "00"' is not assignable... Remove this comment to see the full error message
          minutes: parseInt(minutes, 10),
        });
      }
    }
    return _.concat(arrayAMHours, arrayPMHours);
  },
  getTimeFromMidnight(time: any, timezone: any, format: any) {
    const midnight = momentTimezone(time).tz(timezone).startOf('day');
    return momentTimezone(time).tz(timezone).diff(midnight, format);
  },
  isWeekend(timestamp: any, timezone: any) {
    const weekday = momentTimezone(timestamp).tz(timezone).weekday();
    return weekday === 0 || weekday === 6;
  },
  isInPast(timestamp: any, timezone: any) {
    if (timezone) {
      return momentTimezone().tz(timezone).isAfter(timestamp);
    }
    return moment().isAfter(timestamp);
  },
  isGenericEmail(email: any) {
    return (
      _.size(email.match(/(\W|^)[\w.+-]*hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail(\W|$)/)) > 0
    );
  },
  getBuildInfo() {
    // because the build info is only added to the template in `yarn build` (not `yarn run`)
    if (window.location.hostname.match(/localhost/)) {
      return {
        version: '0.0.0',
        date: new Date().toString(),
        src: '0000000000000000000000000000000000000000',
        test: true,
      };
    }
    let buildInfo = null;
    try {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      buildInfo = utils.parseJSON(document.lastChild.nodeValue);
    } catch (err) {
      buildInfo = null;
    }

    return buildInfo || {};
  },
  getLastPartOfURL(url: any) {
    return url.substr(url.lastIndexOf('/') + 1);
  },
  generateRandomString({ length }: any) {
    return Math.random()
      .toString(36)
      .substring(2, length + 2);
  },
  formatPhoneNumber(phone: any) {
    try {
      const phoneNumber = parsePhoneNumberFromString(phone);
      if (phoneNumber) return phoneNumber.formatNational();
      return phone;
    } catch (err) {
      return phone;
    }
  },
  // this could be improved so the type removes the __typename from the object
  removeTypenames<T>(value: T): T {
    if (value === null || value === undefined) {
      return value;
    }
    if (Array.isArray(value)) {
      return value.map((v) => utils.removeTypenames(v)) as T;
    }
    if (typeof value === 'object') {
      const newObj = {} as Record<string, any>;
      Object.entries(value).forEach(([key, v]) => {
        if (key !== '__typename') {
          newObj[key] = utils.removeTypenames(v);
        }
      });
      return newObj as T;
    }
    return value;
  },
  omitValueRecursively(object: any, valueToOmit: string) {
    const resultToReturn: any = _.transform(object, (result, value, key) => {
      result[key] = _.isObject(value) && valueToOmit in value ? _.omit(value, valueToOmit) : value;
    });
    return resultToReturn;
  },
  sortArray(data: any, sortBy: any) {
    return data.sort((a: any, b: any) => {
      return a[sortBy] - b[sortBy];
    });
  },
  humanizeDuration(time: any, format = 'minutes') {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    const hours = moment.duration(time, format).hours();
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    const minutes = moment.duration(time, format).minutes();
    if (minutes === 0) return `${hours} ${hours > 1 ? 'hours' : 'hour'}`;
    if (hours === 0) return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`;
    return `${hours} ${hours > 1 ? 'hours' : 'hour'} and ${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`;
  },
  pluralize(value: any, quantity: any) {
    if (quantity <= 1) return value;
    return pluralize.plural(value);
  },
  newObjectId() {
    const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
    const objectId =
      timestamp +
      'xxxxxxxxxxxxxxxx'
        .replace(/[x]/g, () => {
          return Math.floor(Math.random() * 16).toString(16);
        })
        .toLowerCase();

    return objectId;
  },
  prependURLProtocol(url: any) {
    if (url.match(/^.+:\/\//)) return url;
    return `https://${url.replace(/^[:/]+/, '')}`;
  },
  getNameInitials(name: any) {
    if (!name) {
      return '';
    }
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    let initials = [...name.matchAll(rgx)] || [];
    initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();

    return initials;
  },
  downloadTextFile: (() => {
    const a: HTMLAnchorElement = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    return (data: string, fileName: string) => {
      const blob = new Blob([data], { type: 'octet/stream' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      // remove anchor element after 2 seconds
      setTimeout(() => {
        a.remove();
      }, 2000);
    };
  })(),
};

export { utils, update };
