import React, { useEffect, useState } from 'react';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { Button, BoxMessage, LoadingOverlay } from 'components/common';
import { askablePlusUtils } from 'lib/askablePlus';
import { bookingUtils } from 'lib/booking';
import { teamUtils } from 'lib/teams';
import { utils } from 'lib/utils';
import { taxes } from 'lib/taxes';
import { analytics } from 'lib/analytics';
import { location } from 'lib/location';
import countryCodeData from 'lib/data/phoneCountryCodes.json';
import { useFetchTeamByIdQuery } from 'generated/graphql';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { FetchCreditPacksQuery } from 'data/queries/credits/fetchCreditPacks';
import { useFeatureFlags } from 'feature-flags';

// Queries
import projectAskablePlusCreditPrice from 'data/queries/project/projectAskablePlusCreditPrice';

// Mutations
import submitAskablePlusProjectMutation from 'data/mutations/project/submitAskablePlusProject';

// External components
import ModalCompleteAskablePlusBrief from 'components/askablePlus/reviewSubmit/modalCompleteAskablePlusBrief';

// Styles
import './styles/confirmBriefStyles.scss';

function ConfirmAskablePlusBrief(props: any) {
  const { details: clientDetails } = useConnectedClient();
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const askablePlusBriefSteps = _.get(askablePlusBrief, 'steps');
  const [openCompleteOrderModal, setOpenCompleteOrderModal] = useState(false);
  const [expandedText, setExpandedText] = useState({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { data: creditPacks } = useQuery(FetchCreditPacksQuery);

  const { MULTIREGION_COUNTRIES } = useFeatureFlags(['MULTIREGION_COUNTRIES']);

  const teamByIdData = useFetchTeamByIdQuery({
    variables: {
      _id: clientDetails?.ConnectedTeam?._id,
    },
  });
  const briefObj = {
    ..._.omit(askablePlusBrief, ['steps', 'team', 'users', 'owner', 'bookings', 'pricing', 'rating', 'admin']),
    askable_plus: {
      ...askablePlusBrief.askable_plus,
      research_type: {
        ...utils.omitValueRecursively(askablePlusBrief.askable_plus?.research_type, 'Booking'),
      },
    },
  };
  const askablePlusCredits = useQuery(projectAskablePlusCreditPrice, {
    variables: {
      project: utils.removeTypenames(briefObj),
    },
  });
  const [submitAskablePlusProject] = useMutation(submitAskablePlusProjectMutation);

  const taxesForCountry: any = taxes.getTaxes();
  const currency = teamUtils.getCurrency();

  // Credits to buy is equals to the number of participants needed minus the number of credits the team currently has
  const creditsToBeUsed = _.get(askablePlusCredits, 'data.projectAskablePlusCreditPrice.total_credits');
  const creditsRemaining = teamUtils.getCredits(teamByIdData.data?.teamById);
  const creditsToBuy = creditsToBeUsed - creditsRemaining;
  const pricePerParticipant = bookingUtils.getPricePerParticipant(creditPacks?.creditPacks as [], creditsToBuy);
  const projectPrice = creditsToBuy * pricePerParticipant;
  const totalParticipants = _.get(askablePlusCredits, 'data.projectAskablePlusCreditPrice.total_participants');
  const projectPriceTaxes = taxesForCountry?.tax_rate > 0 ? (projectPrice * (taxesForCountry?.tax_rate || 0)) / 100 : 0;
  const projectPriceAfterTaxes = projectPrice + projectPriceTaxes;

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else {
      props.updateLastStep({
        step: 'Review & Submit',
        subStep: `/askable-plus/${askablePlusBrief._id}/review-submit/confirm-brief`,
        stepId: 'review_submit_confirm_brief',
      });
      props.renderRightContent(null);
    }
  }, []);

  const onClickFinish = () => {
    setError('');
    setOpenCompleteOrderModal(true);
  };

  const onCloseSubmitBrief = () => {
    setOpenCompleteOrderModal(false);
  };

  const onSubmitBrief = (transactionId: any) => {
    setLoading(true);
    setOpenCompleteOrderModal(false);

    submitAskablePlusProject({
      variables: {
        _project_id: askablePlusBrief._id,
        _transaction_id: transactionId,
        currency_code: currency.code,
      },
    })
      .then(() => {
        setLoading(false);
        // Track Event Analytics
        analytics.track('askable_plus_brief_submitted', {
          page: window.location.href,
          action: 'Askable Plus brief submitted',
          askablePlusBriefProjectTitle: _.get(askablePlusBrief, 'name'),
        });

        const redirectTo = `/askable-plus/${askablePlusBrief._id}/confirmation`;
        props.history.push({ pathname: redirectTo });
      })
      .catch((e) => {
        setLoading(false);
        setError(_.get(e, 'graphQLErrors[0].message') || 'Error submitting brief');
      });
  };

  const renderCardPrice = () => {
    return (
      <div className="priceCard">
        <p id="__totalCreditsBrief" className="totalCredits">
          {utils.numberWithCommas(creditsToBeUsed)} Credits
        </p>
        {creditsRemaining === 0 && (
          <p className="totalPrice">
            <span>
              {currency.symbol}
              {utils.formatMoney(projectPrice, 2)} {currency.code}
            </span>
            {_.get(taxesForCountry, 'tax_rate') > 0 && (
              <span>
                <br />+ {_.get(taxesForCountry, 'tax_label')}
              </span>
            )}
          </p>
        )}
        {creditsRemaining > 0 && renderCreditsBalanceContainer()}
        {creditsRemaining > 0 && creditsToBuy > 0 && renderCreditsToBuyContainer()}
        <p className="totalInfo">
          • Price <u>includes</u> participant recruitment incentives
        </p>
        <p className="totalInfo">
          • Price <u>includes</u> researcher&apos;s fee
        </p>
        <p className="totalInfo">• Pay by invoice or credit card</p>
      </div>
    );
  };

  const renderCreditsBalanceContainer = () => {
    return (
      <div className="creditsBalanceContainer">
        <span>Your credit balance</span>
        <h3>
          {utils.numberWithCommas(creditsRemaining)} {utils.pluralize('credit', creditsRemaining)}
        </h3>
      </div>
    );
  };

  const renderCreditsToBuyContainer = () => {
    return (
      <div className="creditsToBuyContainer">
        <p>
          {creditsRemaining > 0 ? 'Top up with' : 'Price for'} {utils.numberWithCommas(creditsToBuy)}{' '}
          {utils.pluralize('credit', creditsRemaining)}:
        </p>
        <h3>
          {`${currency.symbol}${utils.formatMoney(projectPrice, 2)} ${currency.code}`}
          {_.get(taxesForCountry, 'tax_rate') > 0 && <span> + {_.get(taxesForCountry, 'tax_label')}</span>}
        </h3>
      </div>
    );
  };

  const renderReviewCardItem = ({
    key,
    icon,
    iconClass,
    title,
    value,
    isMissing = false,
    isWarning = false,
    editLink = null,
    editLinkState = null,
    type = '',
    markdown = null,
  }: any) => {
    return (
      <div className="reviewItemContainer" key={title}>
        <div className="reviewItemIconContainer">
          <img
            className={`reviewItemIcon ${isMissing ? 'missingField' : ''} ${isWarning ? 'warningField' : ''} ${iconClass}`}
            src={`/askablePlus/icons/${icon}`}
            alt="reviewItemIcon"
          />
        </div>
        <div className="reviewItemContent">
          <p className={`reviewItemTitle ${isMissing ? 'missingField' : ''} ${isWarning ? 'warningField' : ''}`}>
            {title}
          </p>
          {type === 'link' && (
            <a id={key} className="reviewItemOption linkField" href={value} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          )}
          {type !== 'link' && markdown && (
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: any; className: string; source: any; }... Remove this comment to see the full error message
            <ReactMarkdown id={key} className="reviewItemOption" source={value} />
          )}
          {type !== 'link' && !markdown && (
            <p id={key} className="reviewItemOption">
              {value}
            </p>
          )}
        </div>
        {editLink && (
          <div
            className="reviewItemContentEdit"
            onClick={() => {
              props.history.push({ pathname: editLink, linkState: editLinkState });
            }}
          >
            Edit
          </div>
        )}
      </div>
    );
  };

  const renderAddRequiredInfoLink = (key: any, linkToAddValue: any, missingLabel?: string, linkState = null) => {
    let linkText = 'Add required info';
    if (missingLabel) linkText = missingLabel;

    return (
      <a
        className="missingField"
        onClick={() => {
          props.history.push({ pathname: linkToAddValue, linkState });
        }}
      >
        {linkText}
      </a>
    );
  };

  const renderFieldValue = (key: any, value: any, linkToAddValue: any, missingLabel = '', linkState = null) => {
    if (value) return value;
    return renderAddRequiredInfoLink(key, linkToAddValue, missingLabel, linkState);
  };

  const renderDueDate = () => {
    const dueDate = momentTimezone(_.get(askablePlusBrief, 'askable_plus.due_date'));
    return `${dueDate.format('dddd, Do MMMM')} (${moment(dueDate).fromNow()})`;
  };

  const renderFrequencyDuration = () => {
    const ai_moderated_quota = _.get(askablePlusBrief, 'askable_plus.research_type.continuous_ai_moderated.quota');
    const researcher_moderated_quota = _.get(
      askablePlusBrief,
      'askable_plus.research_type.continuous_researcher_moderated.quota',
    );
    const duration = _.get(askablePlusBrief, 'askable_plus.continuous_duration');
    return `${ai_moderated_quota} AI Moderated interviews, ${researcher_moderated_quota} Researcher Moderated interviews, over ${duration} months`;
  };

  const renderSessionDuration = (key: any, value: any, type: any, subtype: any, link: any) => {
    if (value) {
      const session = utils.getLabelFromArray(bookingUtils.sessionDurations(type, subtype), value);
      return session;
    }
    return renderAddRequiredInfoLink(key, link);
  };

  const renderStudyDuration = () => {
    const workloadTime = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.time',
    );
    const workloadMeasure = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.measure',
    );
    const workloadFrequency = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.participant_workload.frequency',
    );
    const workloadMeasureLabel = bookingUtils.getMeasureLabel(workloadMeasure, workloadTime > 1);
    const workloadFrequencyLabel = bookingUtils.getFrequencyLabel(workloadFrequency);

    const periodTime = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.period.time',
    );
    const periodFrequency = _.get(
      askablePlusBrief,
      'askable_plus.research_type.longitudinal.longitudinal_study.period.frequency',
    );
    const periodFrequencyLabel = bookingUtils.getFrequencyLabel(periodFrequency, periodTime > 1);

    if (!workloadTime || !periodTime) {
      renderAddRequiredInfoLink(
        'longitudinal_study_duration',
        `/askable-plus/${askablePlusBrief._id}/longitudinal-study/study-duration`,
      );
    }
    return (
      <span>
        {workloadTime} {workloadMeasureLabel} per {workloadFrequencyLabel} to be done over {periodTime}{' '}
        {periodFrequencyLabel}
      </span>
    );
  };

  const renderResearchType = () => {
    const researchTypes: any = [];
    _.map(askablePlusUtils.researchTypes(), (researchType: any) => {
      if (_.get(askablePlusBrief, `askable_plus.research_type[${researchType.key}].quota`)) {
        researchTypes.push(researchType.label);
      }
    });
    if (_.size(researchTypes) > 0) {
      return researchTypes.toString().replaceAll(',', ', ');
    }
    return renderAddRequiredInfoLink(
      'research_type',
      `/askable-plus/${askablePlusBrief._id}/project-setup/research-type`,
    );
  };

  const renderDeliverables = () => {
    const deliverables: any = [];
    _.map(askablePlusUtils.deliverables(), (deliverable: any) => {
      if (_.get(askablePlusBrief, `askable_plus.deliverables[${deliverable.key}]`)) {
        if (deliverable.key === 'other') {
          deliverables.push(`Other: ${_.get(askablePlusBrief, 'askable_plus.deliverables.other_description')}`);
        } else {
          deliverables.push(deliverable.label);
        }
      }
    });
    if (_.size(deliverables) > 0) {
      return deliverables.toString().replaceAll(',', ', ');
    }
    return renderAddRequiredInfoLink(
      'research_type',
      `/askable-plus/${askablePlusBrief._id}/project-setup/deliverables`,
    );
  };

  const renderModerationGuide = () => {
    if (_.get(askablePlusBrief, 'askable_plus.additional_info.moderation_guide_type') === 1) {
      return 'I’d like the researcher to create one';
    }
    return 'I’ll provide it myself';
  };

  const renderLegalPrivacy = () => {
    if (_.get(askablePlusBrief, 'askable_plus.additional_info.nda_guide_type') === 1) {
      return 'Use Askable’s standard NDA';
    }
    return 'Use custom agreements';
  };

  const handleExpandText = (key: any) => {
    const newExpandedText = _.clone(expandedText);
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    newExpandedText[key] = !expandedText[key];
    setExpandedText(newExpandedText);
  };

  const renderLongDescriptions = (key: any, value: any, linkToAddValue: any, missingLabel = '') => {
    if (value) {
      const lines = (value.match(/\n/g) || '').length + 1;
      return (
        <>
          {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          <p className={`longDescriptionText ${expandedText[key] ? 'expanded' : ''}`}>{value}</p>
          {(_.size(value) > 130 || lines > 3) && (
            <a className="additionalExpand" onClick={() => handleExpandText(key)}>
              {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
              {expandedText[key] ? <span>Collapse &uarr;</span> : <span>Expand &darr;</span>}
            </a>
          )}
        </>
      );
    }
    return renderAddRequiredInfoLink(key, linkToAddValue, missingLabel);
  };

  const renderDemographicFilters = (totalFilters: any) => {
    return (
      <span className="reviewItemMissing">
        {totalFilters > 0 && <span>{`${totalFilters} filter${totalFilters > 1 ? 's' : ''} applied`}</span>}
        {totalFilters === 0 &&
          renderAddRequiredInfoLink(
            'demographic_filters',
            `/askable-plus/${askablePlusBrief._id}/audience/demographic-filters`,
            'Add demographic filters',
          )}
      </span>
    );
  };

  const renderScreeningQuestions = (totalQuestions: any) => {
    return (
      <span className="reviewItemMissing">
        {totalQuestions > 0 && <span>{`${totalQuestions} screening question${totalQuestions > 1 ? 's' : ''}`}</span>}
        {totalQuestions === 0 &&
          renderAddRequiredInfoLink(
            'custom_screener',
            `/askable-plus/${askablePlusBrief._id}/audience/custom-screener`,
            'Add screening questions',
          )}
      </span>
    );
  };

  const renderParticipantsLocation = () => {
    const country = askablePlusBrief?.audience?.booking_config?.location?.country;
    const states = askablePlusBrief.askable_plus?.audience?.booking_config?.criteria?.locations?.states ?? [];
    const bounds = askablePlusBrief.askable_plus?.audience?.booking_config?.criteria?.locations?.bounds ?? [];
    const countries = askablePlusBrief.askable_plus?.audience?.booking_config?.criteria?.locations?.countries ?? [];

    const allLocations = [...states, ...bounds, ...countries];
    const countryData = _.find(countryCodeData, { region: country });

    if (allLocations.length === 0 && (MULTIREGION_COUNTRIES || !country)) {
      return renderAddRequiredInfoLink(
        'participant_locations',
        `/askable-plus/${askablePlusBrief._id}/audience/participant-locations`,
      );
    }

    if (allLocations.length === 0) {
      return <span>Anywhere in {countryData?.name}</span>;
    }

    return (
      <ul className="ml-4 list-disc">
        {allLocations.map((l) => (
          <li key={l?.formatted_address}>{l?.formatted_address}</li>
        ))}
      </ul>
    );
  };

  const renderLinkToAssets = () => {
    const links = _.get(askablePlusBrief, 'askable_plus.assets', []);
    if (_.size(links) > 0) {
      return _.map(links, (link: any, index: any) => {
        const displayLink = (
          <a href={_.get(link, 'url')} target="_blank" rel="noopener noreferrer">
            {_.get(link, 'name')}
            {index + 1 < _.size(links) ? ', ' : ''}
          </a>
        );
        return displayLink;
      });
    }
    return renderAddRequiredInfoLink(
      'link_to_assets',
      `/askable-plus/${askablePlusBrief._id}/additional-info/link-to-assets`,
    );
  };

  const renderProjectSetupReviewCard = () => {
    const doesHaveError = _.get(askablePlusBriefSteps, 'project_setup') === 'error';
    return (
      <div
        aria-invalid={doesHaveError ? 'true' : 'false'}
        className={`reviewCard ${doesHaveError ? 'missingFields' : ''}`}
      >
        <p className="cardTitle">Project Setup</p>
        {renderReviewCardItem({
          key: 'project_title',
          icon: 'tagIcon.svg',
          iconClass: 'tagIcon',
          title: 'Project title (Only visible to you)',
          value: renderFieldValue(
            'project_title',
            _.get(askablePlusBrief, 'name'),
            `/askable-plus/${askablePlusBrief._id}/project-setup/project-title`,
            'Add project title',
          ),
          isMissing: _.get(askablePlusBriefSteps, 'project_setup_project_title') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/project-setup/project-title`,
        })}
        {renderReviewCardItem({
          key: 'key_objective',
          icon: 'shortDescriptionIcon.svg',
          iconClass: 'shortDescriptionIcon',
          title: 'Key objective',
          value: renderLongDescriptions(
            'key_objective',
            _.get(askablePlusBrief, 'askable_plus.objective'),
            `/askable-plus/${askablePlusBrief._id}/project-setup/project-brief`,
            'Add key objective',
          ),
          isMissing: _.get(askablePlusBriefSteps, 'project_setup_key_objective') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/project-setup/project-brief`,
        })}
        {renderReviewCardItem({
          key: 'project_context',
          icon: 'descriptionIcon.svg',
          iconClass: 'descriptionIcon',
          title: 'Project context',
          value: renderLongDescriptions(
            'project_context',
            _.get(askablePlusBrief, 'askable_plus.description'),
            `/askable-plus/${askablePlusBrief._id}/project-setup/project-brief`,
            'Add project context',
          ),
          isMissing: _.get(askablePlusBriefSteps, 'project_setup_project_context') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/project-setup/project-brief`,
        })}
        {renderReviewCardItem({
          key: 'research_type',
          icon: 'cameraIcon.svg',
          iconClass: 'cameraIcon',
          title: 'Research type',
          value: renderResearchType(),
          isMissing: _.get(askablePlusBriefSteps, 'project_setup_research_type') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/project-setup/research_type`,
        })}
        {renderReviewCardItem({
          key: 'deliverables',
          icon: 'desktopMobileIcon.svg',
          iconClass: 'desktopMobileIcon',
          title: 'Deliverables',
          value: renderDeliverables(),
          isMissing: _.get(askablePlusBriefSteps, 'project_setup_deliverables') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/project-setup/deliverables`,
        })}
        {!askablePlusUtils.isContinuousDiscoverType(askablePlusBrief)
          ? renderReviewCardItem({
              key: 'due_date',
              icon: 'calendarOkIcon.svg',
              iconClass: 'calendarOkIcon',
              title: 'Due date',
              value: renderDueDate(),
              isMissing: _.get(askablePlusBriefSteps, 'project_setup_due-date') === 'error',
              editLink: `/askable-plus/${askablePlusBrief._id}/project-setup/due-date`,
            })
          : renderReviewCardItem({
              key: 'frequency_duration',
              icon: 'calendarOkIcon.svg',
              iconClass: 'calendarOkIcon',
              title: 'Frequency & duration',
              value: renderFrequencyDuration(),
              isMissing: _.get(askablePlusBriefSteps, 'project_setup_frequency_duration') === 'error',
              editLink: `/askable-plus/${askablePlusBrief._id}/project-setup/frequency-duration`,
            })}
      </div>
    );
  };

  const renderDiscoveryInterviewReviewCard = () => {
    if (!askablePlusUtils.hasDiscoveryInterviewType(askablePlusBrief)) return null;
    const quota = _.get(askablePlusBrief, 'askable_plus.research_type.discovery.quota');
    const sessionDuration = _.get(askablePlusBrief, 'askable_plus.research_type.discovery.session.duration');
    const meetingFormat =
      _.get(askablePlusBrief, 'askable_plus.research_type.discovery.booking_type') === 1 ? 'In-person' : 'Remote';
    const sessionFormat =
      _.get(askablePlusBrief, 'askable_plus.research_type.discovery.session.type') === 1
        ? 'Individual interviews'
        : 'Focus group';
    const doesHaveError = _.get(askablePlusBriefSteps, 'discovery_interview') === 'error';

    return (
      <div
        aria-invalid={doesHaveError ? 'true' : 'false'}
        className={`reviewCard ${doesHaveError ? 'missingFields' : ''}`}
      >
        <p className="cardTitle">Discovery Interview</p>
        {renderReviewCardItem({
          key: 'discovery_interview_quota',
          icon: 'quotaIcon.svg',
          iconClass: 'quotaIcon',
          title: 'Quota',
          value: renderFieldValue(
            'discovery_interview_quota',
            quota ? `${quota} ${utils.pluralize('participant', quota)}` : null,
            `/askable-plus/${askablePlusBrief._id}/discovery-interview/quota`,
          ),
          isMissing: _.get(askablePlusBriefSteps, 'discovery_interview_quota') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/discovery-interview/quota`,
        })}
        {renderReviewCardItem({
          key: 'discovery_interview_session_duration',
          icon: 'calendarTimeIcon.svg',
          iconClass: 'calendarTimeIcon',
          title: 'Session Duration',
          value: renderSessionDuration(
            'discovery_interview_session_duration',
            sessionDuration,
            2,
            null,
            `/askable-plus/${askablePlusBrief._id}/discovery-interview/session-duration`,
          ),
          isMissing: _.get(askablePlusBriefSteps, 'discovery_interview_session_duration') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/discovery-interview/session-duration`,
        })}
        {renderReviewCardItem({
          key: 'discovery_interview_meeting_format',
          icon: 'desktopIcon.svg',
          iconClass: 'desktopIcon',
          title: 'Meeting format',
          value: meetingFormat,
          isMissing: _.get(askablePlusBriefSteps, 'discovery_interview_meeting_format') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/discovery-interview/meeting-format`,
        })}
        {renderReviewCardItem({
          key: 'discovery_interview_session_format',
          icon: 'addPersonIcon.svg',
          iconClass: 'addPersonIcon',
          title: 'Session format',
          value: sessionFormat,
          isMissing: _.get(askablePlusBriefSteps, 'discovery_interview_session_format') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/discovery-interview/meeting-format`,
        })}
      </div>
    );
  };

  const renderUsabilityTestingReviewCard = () => {
    if (!askablePlusUtils.hasUsabilityTestingType(askablePlusBrief)) return null;
    const quota = _.get(askablePlusBrief, 'askable_plus.research_type.usability.quota');
    const sessionDuration = _.get(askablePlusBrief, 'askable_plus.research_type.usability.session.duration');
    const meetingFormat =
      _.get(askablePlusBrief, 'askable_plus.research_type.usability.booking_type') === 1 ? 'In-person' : 'Remote';

    const doesHaveError = _.get(askablePlusBriefSteps, 'usability_testing') === 'error';
    return (
      <div
        aria-invalid={doesHaveError ? 'true' : 'false'}
        className={`reviewCard ${doesHaveError ? 'missingFields' : ''}`}
      >
        <p className="cardTitle">Usability Testing</p>
        {renderReviewCardItem({
          key: 'quota',
          icon: 'quotaIcon.svg',
          iconClass: 'quotaIcon',
          title: 'Quota',
          value: renderFieldValue(
            'quota',
            quota ? `${quota} ${utils.pluralize('participant', quota)}` : null,
            `/askable-plus/${askablePlusBrief._id}/usability-testing/quota`,
          ),
          isMissing: _.get(askablePlusBriefSteps, 'usability_testing_quota') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/usability-testing/quota`,
        })}
        {renderReviewCardItem({
          key: 'usability_testing_session_duration',
          icon: 'calendarTimeIcon.svg',
          iconClass: 'calendarTimeIcon',
          title: 'Session Duration',
          value: renderSessionDuration(
            'usability_testing_session_duration',
            sessionDuration,
            2,
            null,
            `/askable-plus/${askablePlusBrief._id}/usability-testing/session-duration`,
          ),
          isMissing: _.get(askablePlusBriefSteps, 'usability_testing_session_duration') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/usability-testing/session-duration`,
        })}
        {renderReviewCardItem({
          key: 'usability_testing_meeting_format',
          icon: 'desktopIcon.svg',
          iconClass: 'desktopIcon',
          title: 'Meeting format',
          value: meetingFormat,
          isMissing: _.get(askablePlusBriefSteps, 'usability_testing_meeting_format') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/usability-testing/meeting-format`,
        })}
      </div>
    );
  };

  const renderCompetitiveAnalysisReviewCard = () => {
    if (!askablePlusUtils.hasCompetitiveAnalysisType(askablePlusBrief)) return null;
    const quota = _.get(askablePlusBrief, 'askable_plus.research_type.competitive_analysis.quota');
    const doesHaveError = _.get(askablePlusBriefSteps, 'competitive_analysis') === 'error';
    return (
      <div
        aria-invalid={doesHaveError ? 'true' : 'false'}
        className={`reviewCard ${doesHaveError ? 'missingFields' : ''}`}
      >
        <p className="cardTitle">Competitive Analysis</p>
        {renderReviewCardItem({
          key: 'quota',
          icon: 'quotaIcon.svg',
          iconClass: 'quotaIcon',
          title: 'Quota',
          value: renderFieldValue(
            'quota',
            quota ? `${quota} ${utils.pluralize('participant', quota)}` : null,
            `/askable-plus/${askablePlusBrief._id}/competitive-analysis/quota`,
          ),
          isMissing: _.get(askablePlusBriefSteps, 'competitive_analysis_quota') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/competitive-analysis/quota`,
        })}
      </div>
    );
  };

  const renderSurveyReviewCard = () => {
    if (!askablePlusUtils.hasSurveyType(askablePlusBrief)) return null;
    const quota = _.get(askablePlusBrief, 'askable_plus.research_type.survey.quota');
    const sessionDuration = _.get(askablePlusBrief, 'askable_plus.research_type.survey.session.duration');
    const doesHaveError = _.get(askablePlusBriefSteps, 'survey') === 'error';
    return (
      <div
        aria-invalid={doesHaveError ? 'true' : 'false'}
        className={`reviewCard ${doesHaveError ? 'missingFields' : ''}`}
      >
        <p className="cardTitle">Survey</p>
        {renderReviewCardItem({
          key: 'quota',
          icon: 'quotaIcon.svg',
          iconClass: 'quotaIcon',
          title: 'Quota',
          value: renderFieldValue(
            'quota',
            quota ? `${quota} ${utils.pluralize('participant', quota)}` : null,
            `/askable-plus/${askablePlusBrief._id}/survey/quota`,
          ),
          isMissing: _.get(askablePlusBriefSteps, 'survey_quota') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/survey/quota`,
        })}
        {renderReviewCardItem({
          key: 'survey_duration',
          icon: 'calendarTimeIcon.svg',
          iconClass: 'calendarTimeIcon',
          title: 'Survey Duration',
          value: renderSessionDuration(
            'survey_duration',
            sessionDuration,
            3,
            2,
            `/askable-plus/${askablePlusBrief._id}/survey/survey-duration`,
          ),
          isMissing: _.get(askablePlusBriefSteps, 'survey_duration') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/survey/survey-duration`,
        })}
      </div>
    );
  };

  const renderLongitudinalStudyReviewCard = () => {
    if (!askablePlusUtils.hasLongitudinalStudyType(askablePlusBrief)) return null;
    const quota = _.get(askablePlusBrief, 'askable_plus.research_type.longitudinal.quota');
    const doesHaveError = _.get(askablePlusBriefSteps, 'longitudinal_study') === 'error';

    return (
      <div
        aria-invalid={doesHaveError ? 'true' : 'false'}
        className={`reviewCard ${doesHaveError ? 'missingFields' : ''}`}
      >
        <p className="cardTitle">Longitudinal Study</p>
        {renderReviewCardItem({
          key: 'quota',
          icon: 'quotaIcon.svg',
          iconClass: 'quotaIcon',
          title: 'Quota',
          value: renderFieldValue(
            'quota',
            quota ? `${quota} ${utils.pluralize('participant', quota)}` : null,
            `/askable-plus/${askablePlusBrief._id}/longitudinal-study/quota`,
          ),
          isMissing: _.get(askablePlusBriefSteps, 'longitudinal_study_quota') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/longitudinal-study/quota`,
        })}
        {renderReviewCardItem({
          key: 'Longitudinal_study_duration',
          icon: 'calendarTimeIcon.svg',
          iconClass: 'calendarTimeIcon',
          title: 'Study Duration',
          value: renderStudyDuration(),
          isMissing: _.get(askablePlusBriefSteps, 'longitudinal_study_duration') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/longitudinal-study/study-duration`,
        })}
      </div>
    );
  };

  const renderAudienceReviewCard = () => {
    const allFilters = bookingUtils.demographicFilterOptions();
    let bookingTypeFilters = allFilters;
    if (!askablePlusUtils.isProjectForProfessionals(askablePlusBrief)) {
      bookingTypeFilters = _.filter(allFilters, (item: any) => !_.has(item, 'type'));
    }
    const filtersSaved = _.filter(bookingTypeFilters, (item: any) => {
      if (
        _.has(askablePlusBrief, `askable_plus.audience.booking_config.criteria[${item.id}]`) &&
        _.get(askablePlusBrief, `askable_plus.audience.booking_config.criteria[${item.id}]`) !== null
      ) {
        return item;
      }
    });
    const doesHaveError = _.get(askablePlusBriefSteps, 'audience') === 'error';
    return (
      <div
        aria-invalid={doesHaveError ? 'true' : 'false'}
        className={`reviewCard ${doesHaveError ? 'missingFields' : ''}`}
      >
        <p className="cardTitle">Audience</p>
        {!_.get(askablePlusBrief, 'askable_plus.audience.booking_config.criteria.exclude_participants_from_time') &&
          renderReviewCardItem({
            key: 'participant_criteria',
            icon: 'participantListIcon.svg',
            iconClass: 'participantListIcon',
            title: 'Participant criteria',
            value: renderLongDescriptions(
              'participant_criteria',
              _.get(askablePlusBrief, 'askable_plus.audience.description'),
              `/askable-plus/${askablePlusBrief._id}/audience/participant-criteria`,
              'Add criteria',
            ),
            isMissing: _.get(askablePlusBriefSteps, 'audience_participant_criteria') === 'error',
            editLink: `/askable-plus/${askablePlusBrief._id}/audience/participant-criteria`,
          })}
        {_.get(askablePlusBrief, 'askable_plus.audience.booking_config.criteria.exclude_participants_from_time') && (
          <>
            {renderReviewCardItem({
              key: 'demographic_filters',
              icon: 'participantListIcon.svg',
              iconClass: 'participantListIcon',
              title: 'Demographic filters',
              value: renderDemographicFilters(_.size(filtersSaved)),
              isMissing: _.get(askablePlusBriefSteps, 'audience_demographic_filters') === 'error',
              editLink: `/askable-plus/${askablePlusBrief._id}/audience/demographic-filters`,
            })}
            {renderReviewCardItem({
              key: 'Custom screener',
              icon: 'listIcon.svg',
              iconClass: 'listIcon',
              title: 'Custom screener',
              value: renderScreeningQuestions(
                _.size(_.get(askablePlusBrief, 'askable_plus.audience.booking_config.question', [])),
              ),
              isMissing: _.get(askablePlusBriefSteps, 'audience_custom_screener') === 'error',
              editLink: `/askable-plus/${askablePlusBrief._id}/audience/custom-screener`,
            })}
          </>
        )}
        {renderReviewCardItem({
          key: 'incentive_level',
          icon: 'cashIcon.svg',
          iconClass: 'cashIcon',
          title: 'Incentive Level',
          value: `${askablePlusUtils.isProjectForProfessionals(askablePlusBrief) ? 'Premium' : 'Standard'} incentive amount`,
          isMissing: _.get(askablePlusBriefSteps, 'audience_incentives') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/audience/incentives`,
        })}
        {renderReviewCardItem({
          key: 'participant_location',
          icon: 'earthIcon.svg',
          iconClass: 'earthIcon',
          title: 'Participant location',
          value: renderParticipantsLocation(),
          isMissing: _.get(askablePlusBriefSteps, 'audience_participant_locations') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/audience/participant-locations`,
        })}
      </div>
    );
  };
  const renderAdditionalInfoReviewCard = () => {
    const doesHaveError = _.get(askablePlusBriefSteps, 'additional_info') === 'error';
    return (
      <div
        aria-invalid={doesHaveError ? 'true' : 'false'}
        className={`reviewCard ${doesHaveError ? 'missingFields' : ''}`}
      >
        <p className="cardTitle">Additional Info</p>
        {renderReviewCardItem({
          key: 'link_to_assets',
          icon: 'linkToTaskIcon.svg',
          iconClass: 'linkToTaskIcon',
          title: 'Link to assets',
          value: renderLinkToAssets(),
          isMissing: _.get(askablePlusBriefSteps, 'additional_info_link_to_assets') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/additional-info/link-to-assets`,
        })}
        {renderReviewCardItem({
          key: 'moderation_guide',
          icon: 'notesIcon.svg',
          iconClass: 'notesIcon',
          title: 'ModerationGuide',
          value: renderModerationGuide(),
          isMissing: _.get(askablePlusBriefSteps, 'additional_info_moderation_guide') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/additional-info/moderation-guide`,
        })}
        {renderReviewCardItem({
          key: 'your_timezone',
          icon: 'clockIcon.svg',
          iconClass: 'clockIcon',
          title: 'Your timezone',
          value: location.getTimezoneText(_.get(askablePlusBrief, 'askable_plus.additional_info.timezone')),
          isMissing: _.get(askablePlusBriefSteps, 'additional_info_your_timezone') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/additional-info/your-timezone`,
        })}
        {renderReviewCardItem({
          key: 'legal_privacy',
          icon: 'listOkIcon.svg',
          iconClass: 'listOkIcon',
          title: 'Legal & Privacy',
          value: renderLegalPrivacy(),
          isMissing: _.get(askablePlusBriefSteps, 'additional_info_legal_privacy') === 'error',
          editLink: `/askable-plus/${askablePlusBrief._id}/additional-info/legal-privacy`,
        })}
        {renderReviewCardItem({
          key: 'additional_notes',
          icon: 'descriptionIcon.svg',
          iconClass: 'descriptionIcon',
          title: 'Additional notes',
          value: renderLongDescriptions(
            'additional_notes',
            _.get(askablePlusBrief, 'askable_plus.additional_info.notes'),
            `/askable-plus/${askablePlusBrief._id}/additional-info/additional-notes`,
            'Add notes',
          ),
          editLink: `/askable-plus/${askablePlusBrief._id}/additional-info/additional-notes`,
        })}
      </div>
    );
  };

  const renderStep = (step: any, title: any, description = '', divider = false) => {
    return (
      <>
        <span className="stepNumber">{`Step ${step}`}</span>
        <span className="stepTitle font--headline">{title}</span>
        {description && <span className="stepDescription">{description}</span>}
        {divider && <div className="stepDivider" />}
      </>
    );
  };

  const renderStepCard = (icon: any, description: any) => {
    return (
      <div className="stepCardContainer">
        <img className="stepCardIcon" src={`/askablePlus/icons/${icon}`} alt="icon" />
        <span className="stepCardDescription">{description}</span>
      </div>
    );
  };

  const disableButton =
    _.get(askablePlusBriefSteps, 'project_setup') === 'error' ||
    _.get(askablePlusBriefSteps, 'additional_info') === 'error';

  return (
    <div className="createAskablePlusBriefContent reviewSubmitPage">
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <div className="reviewSubmitContainer">
        <div className="reviewSubmitContent">
          <h1 className="title">Project Summary</h1>
          {error && (
            <BoxMessage type="error" className="boxErrorMessage">
              <p className="error">{error}</p>
            </BoxMessage>
          )}
          {renderCardPrice()}
          {renderProjectSetupReviewCard()}
          {renderDiscoveryInterviewReviewCard()}
          {renderUsabilityTestingReviewCard()}
          {renderCompetitiveAnalysisReviewCard()}
          {renderSurveyReviewCard()}
          {renderLongitudinalStudyReviewCard()}
          {renderAudienceReviewCard()}
          {renderAdditionalInfoReviewCard()}
          <div className="checkoutButtonContainer">
            <Button
              label="Go to checkout"
              className="button checkoutButton"
              onClick={onClickFinish}
              disabled={disableButton || false}
            />
          </div>
        </div>
      </div>
      <div className="stepsContainer">
        <div className="stepsContent">
          {renderStep(
            1,
            'You provide a research plan',
            'Our team will be in touch to go over your research plan.',
            true,
          )}
          {renderStep(
            2,
            'We source an Askable+ researcher',
            'We’ll shortlist the 3 most suitable candidates for you to choose from. All of our researchers are individually vetted and have at least 5+ years of experience.',
            true,
          )}

          {renderStep(
            3,
            'Meet and brief your Askable+ researcher',
            'This is usually done via video chat and takes about 1 hour.',
            true,
          )}
          {renderStep(4, 'Your researcher will begin')}
          {renderStepCard('personListIcon.svg', 'Participant recruitment on Askable')}
          {renderStepCard('coffeeIcon.svg', 'Interviews and fieldwork')}
          {renderStepCard('documentsIcon.svg', 'Data preparation and analysis')}
          {renderStepCard('lampIcon.svg', 'Synthesis and insights')}
          {renderStepCard('documentIcon.svg', 'Reporting and recommendations')}
        </div>
      </div>
      <ModalCompleteAskablePlusBrief
        brief={askablePlusBrief}
        totalParticipants={totalParticipants}
        creditsToBeUsed={creditsToBeUsed}
        projectPrice={projectPrice}
        projectPriceTaxes={projectPriceTaxes}
        pricePerParticipant={pricePerParticipant}
        projectPriceAfterTaxes={projectPriceAfterTaxes}
        creditsRemaining={creditsRemaining}
        creditsToBuy={creditsToBuy}
        team={teamByIdData.data?.teamById}
        open={openCompleteOrderModal}
        onClose={onCloseSubmitBrief}
        onConfirmBrief={onSubmitBrief}
      />
    </div>
  );
}

export default deprecatedWithRouter(ConfirmAskablePlusBrief);
