import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { BuyCredits, InviteTeammate } from 'components/common';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { globalVariables } from 'lib/globalVariables';
import _ from 'lodash';
import noop from 'lodash/noop';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal as ChakraModal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from 'ui';
import type { Stripe } from '@stripe/stripe-js';

// Mutations
import { useConnectedClient } from 'context/ConnectedClientContext';
import { OperationalOffices } from 'generated/graphql';
import { useSwitchTeam } from 'src/hooks/useSwitchTeam';

// Create a new context for the app
export type AppContextType = {
  onSwitchToDifferentTeam: ReturnType<typeof useSwitchTeam>['switchTeam'];
  onOpenInviteTeamMemberModal: (callback?: null | (() => void), enforcePhone?: boolean) => void;
  onOpenBuyCreditsModal: (p?: any) => void;
  onCloseBuyCreditsModal: (p?: any) => void;
  onUpdateLoading: () => void;
};

export const AppContext = React.createContext<AppContextType | undefined>(undefined);
export function useAppContext() {
  const ctx = useContext(AppContext);

  if (ctx === undefined) {
    throw new Error('useAppContext cannot be used outside of AppProvider');
  }

  return ctx;
}

// Creates a provider Component
export function AppProvider(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const connectedClient = useConnectedClient();

  const getStripePublishableKey = useCallback(() => {
    // Method to return a proper stripe publishable key. Given that we have multiple instances running on Stripe
    //  we need to check whats the client billing country code to define which instance to use.
    // Stripe publishable keys can be exposed on the code as they are only used for retrieving data. More info here: https://stripe.com/docs/keys#obtain-api-keys
    const { environment } = globalVariables.getEnvironmentVariables();
    const operational_office = connectedClient.details?.ConnectedTeam?.operational_office || OperationalOffices.Au;
    switch (operational_office) {
      case OperationalOffices.Uk:
        if (environment === 'PROD') {
          return 'pk_live_51MVp70E6ozopCEReCs83smifIVvBEfFVJuewNvI8Z0jcIMLY7ZoNxcgfweMm5KodkG1YLA4xEAmUAVazXZ4LW4LR00TnCqLCVb';
        }
        return 'pk_test_51MVp70E6ozopCERexz3R5QUZT7AcZdKMcFzg8CVuUogYOQcxt8OXi7ukJ8C0CKO7HUBxwoze3esUDQFF28Zwd0iB00GDEuZgjj';
      case OperationalOffices.Au:
        if (environment === 'PROD') {
          return 'pk_live_s1ILHCDEtG6gSSUx5B0vPcat';
        }
        return 'pk_test_LvT3buUQWIybpf7SZr4y3HFl';
      default:
        if (environment === 'PROD') {
          return 'pk_live_s1ILHCDEtG6gSSUx5B0vPcat';
        }
        return 'pk_test_LvT3buUQWIybpf7SZr4y3HFl';
    }
  }, []);
  const [stripeKey, setStripeKey] = useState<Promise<Stripe | null> | null>(null);
  const [inviteTeamMember, setInviteTeamMember] = useState({
    open: false,
    callback: noop,
    enforcePhone: false,
  });
  const [buyCredits, setBuyCredits] = useState({
    creditsToBuy: 0,
    openBuyCreditsModal: false,
  });
  const [loading, setLoading] = useState(false);
  const [teamId, setTeamId] = useState(null);

  const onUpdateStripeKey = () => {
    if (connectedClient.authState === 'authenticated') {
      const newStripeKey = getStripePublishableKey();
      setStripeKey(loadStripe(newStripeKey));
    }
  };

  const { switchTeam } = useSwitchTeam({ updateClientDetails: connectedClient.actions.updateClientDetails });

  useEffect(() => {
    const newStripeKey = getStripePublishableKey();
    setStripeKey(loadStripe(newStripeKey));
  }, []);

  const onOpenInviteTeamMemberModal = (callback?: null | (() => void), enforcePhone = false) => {
    setInviteTeamMember({
      open: true,
      callback: callback || noop,
      enforcePhone,
    });
  };

  const onCloseBuyCreditsModal = (param: any) => {
    setBuyCredits({
      ...buyCredits,
      openBuyCreditsModal: false,
    });
    if (param.shouldGoBack) navigate('..');
    if (param.redirect) navigate({ pathname: param.redirect }, { replace: true });
  };

  const onOpenBuyCreditsModal = (param: any) => {
    if (buyCredits.openBuyCreditsModal) return;
    // It should push only when the current URL is not /credits/order already
    if (location.pathname !== '/credits/order') {
      navigate(
        {
          pathname: '/credits/order',
        },
        {
          state: {
            _booking_id: _.get(param, '_booking_id'),
          },
        },
      );
    }

    setTeamId(param.team_id);
    setBuyCredits({
      creditsToBuy: param.creditsToBuy || 100,
      openBuyCreditsModal: true,
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ creditsToBuy: any; openBuyCred... Remove this comment to see the full error message
      onBuyCreditsSuccessCallback: param.onSuccess,
      onBuyCreditsCloseCallback: param.onClose,
    });
  };

  const onUpdateLoading = () => {
    setLoading(!loading);
  };

  const onSwitchToDifferentTeam = async (id: string) => {
    return switchTeam(id);
  };

  return (
    <AppContext.Provider
      /* eslint-disable react/jsx-no-constructed-context-values */
      value={{
        onOpenInviteTeamMemberModal,
        onOpenBuyCreditsModal,
        onCloseBuyCreditsModal,
        onUpdateLoading,
        onSwitchToDifferentTeam,
      }}
    >
      {props.children}
      <ChakraModal
        size="3xl"
        isOpen={inviteTeamMember.open}
        onClose={() => {
          setInviteTeamMember({
            open: false,
            callback: noop,
            enforcePhone: false,
          });
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite people to {connectedClient?.details?.team?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InviteTeammate
              title={null}
              client={props.client}
              onClose={() => {
                setInviteTeamMember({
                  open: false,
                  callback: noop,
                  enforcePhone: false,
                });
              }}
              onDone={() => inviteTeamMember.callback && inviteTeamMember.callback()}
              showCloseIcon
              enforcePhone={inviteTeamMember.enforcePhone}
            />
          </ModalBody>
        </ModalContent>
      </ChakraModal>
      <Elements stripe={stripeKey}>
        <ChakraModal isOpen={buyCredits.openBuyCreditsModal} onClose={noop} size="full" trapFocus={false}>
          <ModalBody>
            <ModalContent>
              <BuyCredits
                client={props.client}
                creditsToBuy={buyCredits.creditsToBuy}
                team_id={teamId}
                onClose={(params: any) => {
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onBuyCreditsCloseCallback' does not exis... Remove this comment to see the full error message
                  if (buyCredits.onBuyCreditsCloseCallback) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onBuyCreditsCloseCallback' does not exis... Remove this comment to see the full error message
                    buyCredits.onBuyCreditsCloseCallback();
                  } else {
                    onCloseBuyCreditsModal({
                      shouldGoBack: _.get(params, 'shouldGoBack', true),
                      redirect: _.get(params, 'redirect', null),
                    });
                  }
                }}
                onUpdateStripeKey={onUpdateStripeKey}
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'onBuyCreditsSuccessCallback' does not ex... Remove this comment to see the full error message
                onSuccess={buyCredits.onBuyCreditsSuccessCallback}
              />
            </ModalContent>
          </ModalBody>
        </ChakraModal>
      </Elements>
    </AppContext.Provider>
  );
}

export default deprecatedWithRouter(AppProvider);
