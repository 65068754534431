import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { withAppContext } from 'components/HOCS';
import { Modal, Button, LoadingOverlay, BoxMessage } from 'components/common';
import { utils } from 'lib/utils';
import { taxes } from 'lib/taxes';
import { teamUtils } from 'lib/teams';
import { Coins } from 'lucide-react';

// Styles
import './styles/modalCompleteAskablePlusBriefStyles.scss';
import { Teams } from 'generated/graphql';
import { useConnectedClient } from 'context/ConnectedClientContext';

type Props = {
  brief: any;
  totalParticipants: number;
  creditsToBeUsed: string;
  projectPrice: any;
  projectPriceTaxes: any;
  projectPriceAfterTaxes: any;
  pricePerParticipant: any;
  creditsRemaining: number;
  creditsToBuy: number;
  team: Teams;
  open: boolean;
  onClose: () => void;
  onConfirmBrief: (transactionId: string) => void;
  context: any;
  error: any;
  loading: boolean;
};

function ModalCompleteAskablePlusBrief(props: Props) {
  const {
    totalParticipants,
    creditsToBeUsed,
    projectPrice,
    projectPriceTaxes,
    projectPriceAfterTaxes,
    pricePerParticipant,
    creditsRemaining,
    creditsToBuy,
    team,
  } = props;
  const clientDetails = useConnectedClient();

  const onClickBuyBulk = () => {
    props.onClose();

    props.context.onOpenBuyCreditsModal({
      team_id: team._id,
      creditsToBuy: 10000,
      onSuccess: (purchaseCreditsData: any) => onCompleteSubmitBrief(purchaseCreditsData),
    });
  };

  const onCompleteSubmitBrief = async (purchaseCreditsData?: any) => {
    let transactionId = '';
    if (purchaseCreditsData) {
      const transactionSorted = utils.sortArray(_.get(purchaseCreditsData, 'Transaction'), '_id');
      transactionId = _.get(transactionSorted.slice(-1), '[0]._id');
      clientDetails.actions.updateClientDetails({
        ...clientDetails.details,
        team,
      });
      props.context.onCloseBuyCreditsModal({ shouldGoBack: true });
    }
    props.onConfirmBrief(transactionId);
  };

  const renderErrorContainer = () => {
    if (props.error) {
      return (
        <BoxMessage type="error mbottom20">
          <span className="errorMessage">{props.error}</span>
        </BoxMessage>
      );
    }
  };

  const renderCostContainer = () => {
    const taxesForCountry: any = taxes.getTaxes();
    const currency = teamUtils.getCurrency();

    return (
      <div className="costToBuyCreditsContainer">
        <p>
          You need another <strong>{utils.numberWithCommas(creditsToBuy)} credits</strong>
        </p>
        <div className="totalAmountContainer">
          <div className="breakdownSubtitleContainer">
            <div className="child">
              <span>
                {utils.numberWithCommas(creditsToBuy)} credits ({currency.symbol}
                {pricePerParticipant} {currency.code} each)
              </span>
              <span>
                {currency.symbol}
                {utils.formatMoney(projectPrice, 2)} {currency.code}
              </span>
            </div>
            {(taxesForCountry?.tax_rate || 0) > 0 && (
              <div className="child">
                <span>{taxesForCountry?.tax_label}</span>
                <span>
                  {currency.symbol}
                  {utils.formatMoney(projectPriceTaxes || projectPrice, 2)} {currency.code}
                </span>
              </div>
            )}
          </div>
          <div className="totalContainer">
            <div className="child">
              <span>Total</span>
              <span className="total">
                {currency.symbol}
                {utils.formatMoney(projectPriceAfterTaxes || projectPrice, 2)} {currency.code}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderButtonLabel = () => {
    if (creditsToBuy > 0 && creditsRemaining === 0) return `Buy ${creditsToBuy} credits`;
    if (creditsToBuy > 0) return `Top-up with ${creditsToBuy} credits`;
    return 'Complete Order';
  };

  const participantLabel = totalParticipants === 1 ? 'participant' : 'participants';

  return (
    <Modal open={props.open} onClose={props.onClose} className="modalCompleteAskablePlusBrief">
      {props.loading && <LoadingOverlay style={{ opacity: 0.7 }} />}
      <div className="contentModal">
        {renderErrorContainer()}
        <div className="creditsContainer">
          <div className="topHeaderContainer">
            <div className="creditsDetailsContainer">
              <h3>
                {totalParticipants} x Askable+ {participantLabel}
              </h3>
            </div>
          </div>
          <div className="creditsContainer">
            <div className="title">
              <h2>{utils.numberWithCommas(creditsToBeUsed)} credits</h2>
              <a
                href="https://help.askable.com/en/articles/4254373-ways-of-buying-credits"
                target="blank"
                className="mleft15"
              >
                (What&apos;s a credit?)
              </a>
            </div>
            <p>Incentives included</p>
          </div>
        </div>
        <div className="prepaidCreditsBalanceContainer">
          <p className="font--bold">Your prepaid credits balance</p>
          <div className="numberOfCredits">
            <Coins color="#b4b4b4" className="h-4 w-4" />
            <p className={`${creditsRemaining === 0 ? 'noCredits' : ''}`}>
              <strong>
                {utils.numberWithCommas(creditsRemaining)} credit{creditsRemaining > 1 ? 's' : ''}
              </strong>{' '}
              remaining
            </p>
          </div>
        </div>
        {creditsToBuy > 0 && renderCostContainer()}
        <div className="buttonsContainer">
          <Button
            label={renderButtonLabel()}
            size="default"
            className="btnComplete"
            onClick={() => {
              if (creditsToBuy > 0) {
                props.onClose();

                sessionStorage.setItem('creditsToBuy', creditsToBeUsed);
                props.context.onOpenBuyCreditsModal({
                  team_id: team._id,
                  creditsToBuy,
                  onSuccess: (purchaseCreditsData: any) => onCompleteSubmitBrief(purchaseCreditsData),
                });
              } else {
                onCompleteSubmitBrief();
              }
            }}
          />
          <p>
            or buy a bulk credit pack and
            <a
              className="mleft5"
              onClick={() => {
                onClickBuyBulk();
              }}
            >
              save up to 30%
            </a>
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default deprecatedWithRouter(withAppContext(ModalCompleteAskablePlusBrief));
