import { gql } from '@apollo/client';

export default gql`
  query Taxes {
    taxes {
      _id
      country_code
      currency_symbol
      tax_label
      tax_rate
      tax_number_label
      xero_revenue_account_code
      xero_discount_account_code
    }
  }
`;
