type ReturnTypes = {
  clientsAppURI: string;
  environment: string;
  sessionsAppURI: string;
  graphqlURI: string;
  participantsAppURI: string;
  enableIntercom: boolean;
  isDemo?: boolean;
  subscriptionURI: string;
  featureFlagsApiKey: string;
};
const globalVariables = {
  getEnvironmentVariables(): ReturnTypes {
    const envVariablesToReturn = (() => {
      const envVariables = {
        clientsAppURI: window.location.origin,
        environment: import.meta.env.VITE_ENVIRONMENT || 'DEV',
        sessionsAppURI: 'https://sessions-dev.askable.com',
        graphqlURI: 'https://graphql-dev.askable.com/graphql',
        participantsAppURI: 'https://my-dev.askable.com',
        subscriptionURI: 'wss://graphql-dev.askable.com/graphql',
        enableIntercom: !window.location.hostname.startsWith('iframe-'),
        isDemo: window.location.hostname === 'demo.askable.com' || localStorage.getItem('isDemo') === 'true',
        featureFlagsApiKey: import.meta.env.VITE_SPLIT_API_KEY,
      };

      if (envVariables.isDemo && import.meta.env.VITE_SPLIT_DEMO_API_KEY) {
        envVariables.featureFlagsApiKey = import.meta.env.VITE_SPLIT_DEMO_API_KEY;
      }

      switch (import.meta.env.VITE_ENVIRONMENT) {
        case 'PREVIEW':
        case 'DEV':
          envVariables.sessionsAppURI = envVariables.isDemo
            ? 'https://sessions-demo.askable.com'
            : 'https://sessions-dev.askable.com';
          envVariables.graphqlURI = 'https://graphql-dev.askable.com/graphql';
          envVariables.participantsAppURI = 'https://my-dev.askable.com';
          envVariables.subscriptionURI = 'wss://graphql-dev.askable.com/graphql';
          envVariables.enableIntercom = true;
          break;
        case 'STAGE':
          envVariables.sessionsAppURI = 'https://sessions-staging.askable.com';
          envVariables.graphqlURI = 'https://graphql-staging.askable.com/graphql';
          envVariables.participantsAppURI = 'https://my-staging.askable.com';
          envVariables.subscriptionURI = 'wss://graphql-staging.askable.com/graphql';
          envVariables.enableIntercom = false;
          break;
        case 'PROD':
          envVariables.sessionsAppURI = 'https://sessions.askable.com';
          envVariables.graphqlURI = 'https://graphql.askable.com/graphql';
          envVariables.participantsAppURI = 'https://my.askable.com';
          envVariables.subscriptionURI = 'wss://graphql.askable.com/graphql';
          envVariables.enableIntercom = !window.location.hostname.match(/iframe/);
          break;
        default:
          if (window.location.hostname.match(/localhost/)) {
            envVariables.sessionsAppURI = 'http://localhost:3001';
            envVariables.graphqlURI = 'http://localhost:4000/graphql';
            envVariables.participantsAppURI = 'https://my-dev.askable.com';
            envVariables.subscriptionURI = 'ws://localhost:4000/graphql';
            envVariables.enableIntercom = true;
          }
      }
      return envVariables;
    })();
    return envVariablesToReturn;
  },
  getScreenSize() {
    return window.outerWidth;
  },
  phone: '(07) 3040 4719',
  isStaging() {
    return window.location.hostname === 'staging.app.askable.com';
  },
};

export { globalVariables };
